








import { Vue, Component } from 'vue-property-decorator'
import { MetaInfo } from 'vue-meta'

import MentorLessonsTable from '@/components/tables/MentorLessonsTable.vue'

@Component({
  components: {
    MentorLessonsTable,
  },
})
export default class LessonsList extends Vue {
  private metaInfo (): MetaInfo {
    return {
      title: 'Уроки',
    }
  }
}
