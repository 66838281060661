



















































import { Bind, Debounce } from 'lodash-decorators'
import { Component, Mixins, Watch } from 'vue-property-decorator'

import MasterGroupsSearch from '@/components/_uikit/search/MasterGroupsSearch.vue'
import MentorLessonCard from '@/components/cards/MentorLessonCard.vue'
import Select from '@/components/_uikit/controls/Select.vue'
import MasterGroupMixin from '@/mixins/MasterGroupMixin'
import PermissionsMixin from '@/mixins/PermissionsMixin'
import DictionaryModule from '@/store/modules/dictionary'
import MentorLessonsModule from '@/store/modules/mentor/lessons'
import { IMentorLessonsFilter, IMentorLessonsFilterType, LessonEntityResource } from '@/store/types'
import MentorEducationModule from '@/store/modules/mentor/education'

@Component({
  components: {
    MasterGroupsSearch,
    MentorLessonCard,
    Select,
  },
})
export default class MentorLessonsTable extends Mixins(MasterGroupMixin, PermissionsMixin) {
  private get filter() {
    return MentorLessonsModule.lessonContainersFilter
  }

  private set filter(filter: IMentorLessonsFilter) {
    MentorLessonsModule.setLessonContainersFilter(filter)
    this.fetchLessons()
  }

  private get lessonStatuses () {
    return DictionaryModule.lessonStatusesFilter
  }

  private get lessonContainers () {
    return MentorLessonsModule.lessonContainers
  }

  private getRedirectObject(lesson: LessonEntityResource) {
    return {
      name: 'manager.education.lessons.item',
      params: {
        groupID: this.currentMasterGroupID,
        lessonID: lesson.id,
      },
    }
  }

  private mounted () {
    if (this.currentMasterGroupMonth || this.filter.monthId) {
      this.filter = {
        ...this.filter,
        monthId: this.currentMasterGroupMonthID as number,
      }
    } else {
      this.fetchLessons()
    }
  }

  private handleNewLesson () {
    this.$router.push({ name: 'manager.education.lessons.item.new', params: { groupID: this.currentMasterGroupID.toString() } })
  }

  @Bind
  @Debounce(500)
  private fetchLessons () {
    MentorLessonsModule.fetchLessonContainers({
      masterGroupId: this.currentMasterGroupID,
      params: this.filter,
    })
      .catch(this.notifyError)
  }

  private handleFilter (field: IMentorLessonsFilterType, value: never) {
    this.filter = {
      ...this.filter,
      [field]: value,
      page: 1,
    }

    if (field === 'monthId') MentorEducationModule.setSavedMasterGroupMounthID(value)
  }

  @Watch('currentMasterGroupID')
  watchCurrentMasterGroupID () {
    MentorEducationModule.setSavedMasterGroupMounthID(null)

    this.filter = {
      ...this.filter,
      monthId: this.currentMasterGroupMonthID as number,
      status: undefined,
    }
  }
}
